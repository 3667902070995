"use client"

import { AlertTriangle } from "lucide-react"
import { Card, CardContent } from "@/components/ui/card"
import { Button } from "@/components/ui/button"
import { useEffect } from "react"
import { useRouter } from "next/navigation"

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string }
  reset: () => void
}) {
  const router = useRouter()

  useEffect(() => {
    console.error(error)
  }, [error])

  return (
    <>
      <div className="container mx-auto flex items-center justify-center min-h-[calc(100vh-200px)]">
        <Card className="w-full max-w-md">
          <CardContent className="flex flex-col items-center justify-center p-6">
            <AlertTriangle className="h-16 w-16 text-destructive mb-4" />
            <h1 className="text-4xl font-bold mb-2">エラー</h1>
            <p className="text-xl font-semibold mb-4 text-muted-foreground">
              予期せぬエラーが発生しました
            </p>
            <Button onClick={() => reset()} variant="outline" className="mb-2">
              もう一度試す
            </Button>
            <Button
              onClick={() => router.push("/")}
              variant="outline"
              className="mb-2"
            >
              トップページに戻る
            </Button>
          </CardContent>
        </Card>
      </div>
    </>
  )
}
